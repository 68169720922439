import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Reeves, C.V., 2023.`}</strong>{`  The Bouvet triple junction: a model of Gondwana fragmentation in Jurassic and Early Cretaceous times. Poster, Netherlands Earth Science Congress, Utrecht, March 2023. `}<a parentName="p" {...{
        "href": "http://www.reeves.nl/upload/NAC2023-CVR-2.pdf"
      }}>{`See poster`}</a>{`.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2022.`}</strong>{`  A comprehensive model for the growth of the oceans that separated Gondwana using fracture-zone retracing. Poster, Netherlands Geoscience Congress, Utrecht, September 2022. `}<a parentName="p" {...{
        "href": "http://www.reeves.nl/upload/2022-reeves-poster-NACgeo.pdf"
      }}>{`See poster`}</a>{`.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., and Souza, P., 2021b.`}</strong>{`  The southern South Atlantic Ocean in the context of a holistic Gondwana dispersal model. Presented at the Geological Society, October 2021. `}<a parentName="p" {...{
        "href": "http://www.reeves.nl/upload/2021b-reeves&souza-london-oct.pdf"
      }}>{`See extended abstract`}</a>{`.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., and Souza, P., 2021a.`}</strong>{` The lost ‘continents’ of the South Atlantic Ocean.  Poster, Netherlands Geoscience Congress, Utrecht, March 2021.
`}<a parentName="p" {...{
        "href": "http://www.reeves.nl/upload/2021a-reeves&souza-NACgeo.pdf"
      }}>{`See poster`}</a>{`.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2020.`}</strong>{` African geology, the Bouvet mantle plume and the early opening of the Gondwana margins.  Poster, Netherlands Geoscience Congress, Utrecht, March 2020. `}<a parentName="p" {...{
        "href": "http://www.reeves.nl/upload/2020-reeves-NAC.pdf"
      }}>{`See poster`}</a>{`.`}</p>
    <p><strong parentName="p">{`Reeves, C.V. & Teasdale, J.P., 2019.`}</strong>{` The creation of the Africa margins and the Mesozoic demise of Gondwana.  Extended abstract, PESGB/GSH Africa E&P conference, London October 2019.  `}<a parentName="p" {...{
        "href": "http://www.reeves.nl/upload/2019a-reeves&teasdale-4pp-pesgb-october.pdf"
      }}>{`See 4p abstract`}</a>{`; `}<a parentName="p" {...{
        "href": "http://www.reeves&teasdale-2pp-pesgb-october.pdf"
      }}>{`See 2p abstract`}</a>{` .`}</p>
    <p><strong parentName="p">{`Richetti, P.C., Schmitt, R.S., & Reeves, C.V., 2018.`}</strong>{` Dividing the South American continent to fit a Gondwana reconstruction: A model based on continental geology. Tectonophysics 747-748:79-98.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2018.`}</strong>{` The development of the East Africa margin during Jurassic and Lower Cretaceous times: a perspective from global tectonics. Petroleum Geoscience, 24, pp 41-56.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., Teasdale, J.P., & Mahanjane, E.S., 2016b.`}</strong>{` Origins of the East Africa margin: Prolonged rifting preceded initiation of transforms in the Kimmeridgian. Extended abstract, East Africa: from Research to Reserves, Geological Society, London 13-15 April 2016.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., Teasdale, J.P., & Mahanjane, E.S., 2016a.`}</strong>{`  Insight into the Eastern Margin of Africa from a New Tectonic Model of the Indian Ocean. Geological Society Special Publication No. 431:Transform Margins: Development, Controls and Petroleum Systems (eds Nemčok, M., Rybár, S., Sinha, S.T., Hermeston, S.A., & Ledvényiová, L.).  doi: `}<a parentName="p" {...{
        "href": "http://doi.org/10.1144/SP431.12"
      }}>{`http://doi.org/10.1144/SP431.12`}</a>{`.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., Teasdale, J.P., & Mahanjane, E.S., 2015.`}</strong>{`  Insight into the East Coast of Africa from a new tectonic model of the early Indian Ocean. 2-page abstract and 4-page appendix. Geological Society of Houston/Petroleum Exploration Society of Great Britain, London, September 2-3.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2015.`}</strong>{` Gondwana made simple: How it fits together, how it came apart. IGCP Workshop, Rio de Janiero, June 22 - July 3.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2014.`}</strong>{` The position of Madagascar within Gondwana and its movements during Gondwana dispersal. Journal of African Earth Sciences. Volume 94, pp 45-57.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2013b.`}</strong>{` The global tectonics of the Indian Ocean and its relevance to India’s western margin. Journal of Geophysics, vol 34, 87-94.`}</p>
    <p><strong parentName="p">{`Reeves, C.V. and Mahanjane, E.S., 2013a.`}</strong>{` Dykes in SE Africa reveal first successful Gondwana spreading axis. Letter to Nature, April 2013 – rejected.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2013e.`}</strong>{` Lebombo: Are we on the edge of Africa? Extended abstract. South African Geophysical Association meeting, Skukuza, South Africa, October.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2013d.`}</strong>{` Global tectonics, rifting and the fabric of Africa. Extended abstract. Society of Exploration Geophysicists, ‘Exploration of Continental Rifts’ workshop, Houston, September 26.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., and Mahanjane, E.S., 2013b.`}</strong>{` Mozambique and its role in the downfall of Gondwana. Extended abstract. Geological Society of Houston/Petroleum exploration society of Great Britain, London, September 11-12.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2013a.`}</strong>{` African Geology and Tectonics through aeromagnetics: from Botswana to Gondwana. Extended abstract. 24th Colloquium of African Geology. Addis Ababa, January.`}</p>
    <p><strong parentName="p">{`Key, R.M., and Reeves, C.V., 2012.`}</strong>{` The post-Gondwana development of East Africa’s coastline with emphasis on the development of the Rovuma Basin. Extended abstract. Geological Society meeting, London, October 24-26.`}</p>
    <p><strong parentName="p">{`Nemčok, M., Stuart, C. J., Rosendahl, B. R., Welker, C., Smith, S., Sheya, C., Sinha, S. T., Choudhuri, M., Allen, R., Reeves, C., Sharma, S. P., Venkatraman S. & Sinha, N., 2012.`}</strong>{` Continental break-up mechanism; lessons from intermediate- and fast-extension settings.In:Mohriak, W. U., Danforth, A., Post, P. J., Brown, D. E., Tari, G. M.,Nemčok, M. and Sinha, S. T. (Eds), Conjugate Divergent Margins. Geological Society of London Special Publication, 369. doi: 10.1144/SP369.14. Published, 05/2012.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2012.`}</strong>{` Global thinking, Geophysics, Gondwana - and India. Extended abstract. Association of Exploration Geophysicists meeting, Hyderabad, October.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2011.`}</strong>{`  Some new thoughts on the early opening of the South Atlantic Ocean. Extended abstract, Geological Society of Houston/Petroleum Exploration Society of Great Britain, London, 2011 September 7-8.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2010.`}</strong>{` A simple model for the tectonic history of the South Atlantic Ocean. (Not published, PDF available).`}</p>
    <p><strong parentName="p">{`Bastia, R., Reeves, C., Pundarika Rao, D., D’Silva, K., Radhakrishna, M., 2010.`}</strong>{` Paleogeographic reconstruction of East Gondwana and evolution of the Indian Continental margin. DCS-DST News, August 2010, pp 2-8.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2009.`}</strong>{`  Re-examining the evidence from plate-tectonics for the initiation of Africa’s passive margins. Extended abstract, Geological Society of Houston/Petroleum Exploration Society of Great Britain, London, 2009 September 9-10.`}</p>
    <p><strong parentName="p">{`De Wit, M.J., Stankiewicz, J., and Reeves., C., 2008.`}</strong>{` Restoring Pan-African-Brasiliano connections: more Gondwana control, less trans-Atlantic  corruption. In Pankhurst, R.J., Trouw, R.A.J., Brito Neves, B.B., and de Wit, M.J. (eds). West Gondwana: pre-Cenozoic correlations across the South Atlantic region.  Geological Society, London, Special Publications 294, 399-412.`}</p>
    <p><strong parentName="p">{`Chavez Gomez, S., Mubu, M.S., and Reeves, C.V., 2004.`}</strong>{`  A catalogue of dykes in eastern and southern Africa.  ITC Publication No 28.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., de Wit, M.J., and Sahu, B.K., 2004.`}</strong>{`  Tight reassembly of Gondwana exposes Phanerozoic shears in Africa as global tectonic players. Gondwana Research, vol.7, pp 7-19. (Gondwana-11 conference).`}</p>
    <p><strong parentName="p">{`Reeves, C.V., Sahu, B.K., and de Wit, M.J., 2002.`}</strong>{`  A re-examination of the paleo-position of Africa’s eastern neighbours in Gondwana.  Journal of African Earth Sciences.  Vol. 34, pp 101-108.`}</p>
    <p><strong parentName="p">{`Reeves, C. V. and de Wit, M.J., 2000.`}</strong>{`  Making ends meet in Gondwana: retracing the transforms of the Indian Ocean and reconnecting continental shear zones.  Terra Nova 12, No.6, 272-282.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2000.`}</strong>{`  The geophysical mapping of Mesozoic dyke swarms in southern Africa and their origin in the disruption of Gondwana.  Journal of African Earth Sciences, 30, 499-513.`}</p>
    <p><em parentName="p">{`Contact `}<a parentName="em" {...{
          "href": "mailto:__reeves.earth@planet.nl__"
        }}>{`__reeves.earth@planet.nl__`}</a>{` for reprints.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      